<template>
  <div class="h-full rounded border border-gray-800">
    <div ref="editorElement" style="height: 100%; width: 100%"></div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';

const editorElement = ref(null);
const editor = ref(null); // Ace Editor instance
const props = defineProps({
  modelValue: String,
  language: {
    type: String,
    default: 'javascript'
  },
  readOnly: {
    type: Boolean,
    default: false
  },
  stringifyCode: {
    type: Boolean,
    default: false
  },
  wrapText: {
    type: Boolean,
    default: true
  }
});

watch(
  () => props.modelValue,
  () => {
    if (editor.value && props.modelValue !== editor.value.getValue()) {
      setEditorValue();
    }
  }
);

const emit = defineEmits(['update:modelValue']);

function setEditorValue() {
  // Handle the case when result = an int
  if (parseInt(props.modelValue) == props.modelValue) {
    editor.value.setValue(JSON.stringify(props.modelValue));
  } else if (props.stringifyCode) {
    editor.value.setValue(JSON.stringify(props.modelValue, null, '  '), -1);
  } else {
    editor.value.setValue(props.modelValue, -1);
  }
}

const initializeEditor = () => {
  editor.value = window.ace.edit(editorElement.value);
  editor.value.setTheme('ace/theme/dracula');
  editor.value.session.setMode(`ace/mode/${props.language}`);

  setEditorValue();

  if (typeof props.modelValue == String && props.modelValue.includes('at line 1, column 1')) {
    editorElement.value.classList.add('text-red-400');
  } else {
    editorElement.value.classList.remove('text-red-400');
  }

  editor.value.getSession().on('change', () => {
    const content = editor.value.getValue();
    emit('update:modelValue', content);
  });

  editor.value.setOptions({
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true,
    enableSnippets: true,
    readOnly: props.readOnly,
    cursorStyle: 'smooth_and_slim',
    highlightActiveLine: true,
    showPrintMargin: false, // The vertical line after 80 characters
    showLineNumbers: !props.readOnly,
    wrap: props.wrapText ? 'free' : false,
    fontSize: '12px',
    fontFamily: 'Fira Code, Fira Mono, Consolas, Menlo, Courier, monospace'
  });

  const customStyles = `
    .ace-dracula .ace_gutter {
      background: #1e293b; /* dark blue */
      color: #64748b; /* grey */
    }
    .ace-dracula .ace_print-margin {
      width: 1px;
      background: #334155; /* dark grey */
    }
    .ace-dracula {
      background-color: #0f172a; /* very dark blue */
      color: #cbd5e1; /* light grey */
    }
    .ace-dracula .ace_cursor {
      color: #2563eb; /* blue */
    }
    .ace-dracula .ace_marker-layer .ace_selection {
      background: #1d4ed8; /* darker blue */
    }
    .ace-dracula.ace_multiselect .ace_selection.ace_start {
      box-shadow: 0 0 3px 0px #0f172a; /* very dark blue */
    }
    .ace-dracula .ace_marker-layer .ace_step {
      background: rgb(198, 219, 236);
    }
    .ace-dracula .ace_marker-layer .ace_bracket {
      margin: -1px 0 0 -1px;
      border: 1px solid #3b5364; /* grey */
    }
    .ace-dracula .ace_marker-layer .ace_active-line {
      background: #1e293b; /* dark blue */
    }
    .ace-dracula .ace_gutter-active-line {
      background-color: #1e293b; /* dark blue */
    }
    .ace-dracula .ace_marker-layer .ace_selected-word {
      border: 1px solid #1d4ed8; /* darker blue */
    }
  .ace-dracula {
    border-radius: 4px; /* Adjusted for rounded corners */
  }
  `;

  // Inject custom styles into the document
  const style = document.createElement('style');
  style.appendChild(document.createTextNode(customStyles));
  document.head.appendChild(style);
};

const loadEditorAndTools = () => {
  const loadScript = (src, callback) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = callback;
    document.body.appendChild(script);
  };

  loadScript('https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.13/ace.js', () => {
    loadScript('https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.13/ext-language_tools.js', initializeEditor);
  });
};

onMounted(() => {
  loadEditorAndTools();
});

onBeforeUnmount(() => {
  if (editor.value) {
    editor.value.destroy();
    editor.value = null;
  }
});
</script>
